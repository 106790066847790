/* math-field:not(:defined) {
  display: none;
} */

math-field::part(menu-toggle) {
  display: none;
}


math-field::part(virtual-keyboard-toggle) {
  display: none;
}

math-field::part(container) {
  flex-flow: row-reverse;
  --highlight-text: "#F3F3F3";

  
}

body {
  





  
}


