.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

html, body {
  overscroll-behavior-y: none;
  overflow-x: hidden;
}

.app-header h1 {
  /* styles for the header */
}

.tagline-text {
  height: inherit;
  width: calc(100%-9rem);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  box-sizing: border-box;



}

.tagline-text h1 {
  font-size: 3.75rem;
}

.tagline-text p {
  font-size: 1.875rem;
}

.feature-highlights-text {
  align-self: left;
  white-space: normal;


}

.feature-highlights-text p {
  font-size: 1.25rem;

}

.feature-highlights-text h1 {
  font-size: 1.5625rem;

}

.sub-tagline {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-tagline h1 {
  font-size: 2.8125rem;
}

.sub-tagline p {
  font-size: 1.875rem;
  
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.video-demo-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 15rem;
  padding-right: 15rem; 
  width: "50%"
  

}

.video-demo-section h1 {
  font-size: 2.8125rem;
  text-align: center;
  margin-bottom: 1.8rem;

  
}

.robot-image-container {
  align-self: center;
  padding-right: 3rem; 
}

.digit-image {
  width: 32.56;
  height: 30.375;
}

.text-container {
  /* styles for the text container */
}

.sign-up-button {
  /* styles for the sign-up button */
}

/* Use media queries for larger screens */
@media (max-width: 768px) {
  
  .robot-image-container {
    /* styles for the robot image container */
    padding-right: 0;
    align-self: center;
    box-sizing: border-box;
    width: 100%;

  }

  .digit-image {
    height: 13.6785rem;
    width: 14.6875rem;
  }

  .text-container {
    /* Adjust padding/margins if necessary */
  }

  .tagline-text {
    height: inherit;
    width: calc(100% - 3rem);
    box-sizing: border-box;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-left: 0rem;
    padding-right: 0rem; 
  }

  .tagline-text h1 {
    font-size: 1.875rem;
  }

  .tagline-text p {
    font-size: 1.125rem;
  }

  .sub-tagline h1 {
    font-size: 1.5rem;
  }

  .sub-tagline p {
    font-size: 1.125rem;
  }

  .video-demo-section {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 3rem;
    padding-right: 3rem; 

  }

  .video-demo-section h1 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.8rem;
    
  }

  .feature-highlights-text {
    text-align: left;
    margin-left: 3rem;
    margin-right: 3rem;
  
  }
  
  .feature-highlights-text p {
    font-size: 1.25rem;
  
  }
  
  .feature-highlights-text h1 {
    font-size: 1.562rem;
  
  }
}
